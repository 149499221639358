.app-dashboard {
    display: flex;
    height: 100vh;
    overflow: hidden;

    & .main-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        box-sizing: border-box;
        width: 100%;
    }

    & .top-header {
        display: flex;
        align-items: center;
        background-color: #fff;
        height: 56px;
        padding-inline: 16px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        z-index: 10;
        transform: translateZ(0);
    }

    & .content {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        margin-top: 56px;
        padding-block: 24px;
        padding-bottom: 56px;
    }
}