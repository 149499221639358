.racetrack-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.racetrack {
    padding: 16px 0px;
    position: relative;
    width: 100%;

    svg {
        width: 100%;
        display: block;
    }
}

.racetrack-wrapper {
    opacity: 1 !important;
    transition: opacity 0.1s ease-in;
}

.race-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

button.btn-option {
    all: unset;
    width: 32px;
    height: 32px;
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover {
        background-color: #f5f5f5;
    }

    &:active {
        background-color: #eee;
    }
}

.racetrack-wrapper .selected {
    pointer-events: none;
    stroke-width: 2.5 !important;
    transition:
        800ms fill,
        800ms stroke;
    fill: transparent;
    stroke: yellow !important;
}

.racetrack-wrapper .color-none {
    fill: white;
    opacity: 0;
    transition: 0ms fill;
}

.racetrack-wrapper .red_num_shape {
    fill: #B51C12;
    transition: 0ms fill;
}

.racetrack-wrapper .red_num_shape.transition {
    fill: #B51C12;
    transition: 800ms fill;
}

.racetrack-wrapper .red_num_shape.highlighted {
    fill: rgba(216, 134, 129, 1);
    transition: 0ms fill;
}

.racetrack-wrapper .green_shape {
    fill: #166958;
    transition: 0ms fill;
}

.racetrack-wrapper .green_shape.transition {
    fill: #166958;
    transition: 800ms fill;
}

.racetrack-wrapper .green_shape.highlighted {
    fill: rgba(131, 175, 166, 1);
    transition: 0ms fill;
}

.racetrack-wrapper .black_num_shape {
    fill: #000000;
    transition: 0ms fill;
}

.racetrack-wrapper .black_num_shape.transition {
    fill: #000000;
    transition: 800ms fill;
}

.racetrack-wrapper .black_num_shape.highlighted {
    fill: rgba(119, 119, 119, 1);
    transition: 0ms fill;
}

.racetrack-wrapper .border {
    pointer-events: none;
    stroke-width: 0.5;
    transition:
        800ms fill,
        800ms stroke;
    fill: transparent;
    stroke: #333;
}

.racetrack-wrapper .sector_color,
.racetrack-wrapper .sector_color.disabled {
    fill: rgba(255, 255, 255, 0);
    transition: 0ms fill;
}

.racetrack-wrapper .sector_color.highlighted {
    fill: rgba(255, 255, 255, 0.47);
    transition: 0ms fill;
}

.racetrack-wrapper .black_num_shape_label,
.racetrack-wrapper .red_num_shape_label,
.racetrack-wrapper .green_shape_label {
    pointer-events: none;
    font-weight: 400;
    font-family: Inter, Arial;
    text-anchor: middle;
    font-size: 9px;
    transition: 0ms fill;
}

.racetrack-wrapper .black_num_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .red_num_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .green_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .even_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .even_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .low_high_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .row_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .third_shape_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .sector_color_label.transition {
    transition: 800ms fill;
}

.racetrack-wrapper .sector_color_label {
    pointer-events: none;
    font-weight: 400;
    font-family: Inter, Arial;
    text-anchor: middle;
    font-size: 8.280000000000001px;
    fill: #333;
    transition: 0ms fill;
}

.racetrack-wrapper .sector_color_label.disabled_label {
    fill: #f9e1cc4d;
}

.racetrack-wrapper .vertical_center {
    dominant-baseline: central;
}

.racetrack-wrapper .red_num_shape_label {
    fill: #F9E1CC;
}

.racetrack-wrapper .black_num_shape_label {
    fill: #F9E1CC;
}

.racetrack-wrapper .green_shape_label {
    fill: #F9E1CC;
}

.dddStandard-wrapper {
    opacity: 1 !important;
    transition: opacity 0.1s ease-in;
}

.dddStandard-wrapper .color-none {
    fill: white;
    opacity: 0;
    transition: 0ms fill;
}

.dddStandard-wrapper .black_num_shape {
    fill: #000000;
    transition: 0ms fill;
}

.dddStandard-wrapper .black_num_shape.transition {
    fill: #000000;
    transition: 800ms fill;
}

.dddStandard-wrapper .black_num_shape.highlighted {
    fill: rgba(119, 119, 119, 1);
    transition: 0ms fill;
}

.dddStandard-wrapper .red_num_shape {
    fill: #B51C12;
    transition: 0ms fill;
}

.dddStandard-wrapper .red_num_shape.transition {
    fill: #B51C12;
    transition: 800ms fill;
}

.dddStandard-wrapper .red_num_shape.highlighted {
    fill: rgba(216, 134, 129, 1);
    transition: 0ms fill;
}

.dddStandard-wrapper .green_shape {
    fill: #166958;
    transition: 0ms fill;
}

.dddStandard-wrapper .green_shape.transition {
    fill: #166958;
    transition: 800ms fill;
}

.dddStandard-wrapper .green_shape.highlighted {
    fill: rgba(131, 175, 166, 1);
    transition: 0ms fill;
}

.dddStandard-wrapper .even_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .even_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .even_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .third_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .third_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .third_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .low_high_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .low_high_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .low_high_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .row_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .row_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .row_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .odd_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .odd_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .odd_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .black_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .black_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .black_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .red_shape {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .red_shape.transition {
    fill: rgba(0, 0, 0, 0.5);
    transition: 800ms fill;
}

.dddStandard-wrapper .red_shape.highlighted {
    fill: rgba(119, 119, 119, 0.5);
    transition: 0ms fill;
}

.dddStandard-wrapper .red_color_label,
.dddStandard-wrapper .black_color_label {
    pointer-events: none;
    stroke: #F9E1CC;
    stroke-width: 1;
    transition: 0ms fill;
}

.dddStandard-wrapper .red_color_label {
    fill: #B51C12;
    transition: 0ms fill, 0ms stroke;
}

.dddStandard-wrapper .black_color_label {
    fill: #000000;
    transition: 0ms fill, 0ms stroke;
}

.dddStandard-wrapper .black_num_shape_label,
.dddStandard-wrapper .red_num_shape_label,
.dddStandard-wrapper .green_shape_label,
.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .low_high_shape_label,
.dddStandard-wrapper .row_shape_label,
.dddStandard-wrapper .third_shape_label {
    pointer-events: none;
    font-weight: 400;
    font-family: Inter, Arial;
    text-anchor: middle;
    transition: 0ms fill;
}

.dddStandard-wrapper .black_num_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .red_num_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .green_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .even_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .even_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .low_high_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .row_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .third_shape_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .outside_small_color_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .red_color_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .black_color_label.transition {
    transition:
        800ms fill,
        800ms stroke;
}

.dddStandard-wrapper .vertical_center {
    dominant-baseline: central;
}

.dddStandard-wrapper .red_num_shape_label {
    fill: #F9E1CC;
}

.dddStandard-wrapper .black_num_shape_label {
    fill: #F9E1CC;
}

.dddStandard-wrapper .green_shape_label {
    fill: #F9E1CC;
}

.dddStandard-wrapper .low_high_shape_label,
.dddStandard-wrapper .third_shape_label,
.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .row_shape_label {
    fill: #F9E1CC;
    transition: 0ms fill;
}

.dddStandard-wrapper .outside_small_color_label,
.dddStandard-wrapper .outside_color_label .small {
    fill: #F9E1CC;
    transition: 0ms fill;
}

.dddStandard-wrapper .green_shape_label,
.dddStandard-wrapper .red_num_shape_label,
.dddStandard-wrapper .black_num_shape_label {
    font-size: 19px;
}

.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .even_shape_label,
.dddStandard-wrapper .low_high_shape_label {
    font-size: 20.007px;
}

.dddStandard-wrapper .row_shape_label {
    font-size: 16.730999999999998px;
}

.dddStandard-wrapper .third_shape_label {
    font-size: 20.007px;
}

.dddStandard-wrapper .border {
    pointer-events: none;
    stroke-width: 1;
    transition:
        800ms fill,
        800ms stroke;
    fill: transparent;
    stroke: #F9E1CC;
}

.racetrack-container {}