.Plan-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Plan {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    border: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Plan .name {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #333;
}

.Plan .price {
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.Plan .price .monthly {
    font-size: 12px;
    color: #666;
}

.Plan .price.off {
    font-weight: 500;
    font-size: 16px;
    color: #999;
    text-decoration: line-through;
}

.Plan .price.off .monthly {
    font-size: 12px;
    color: #666;
}

.Plan button {
    margin-top: 32px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    transition: 0.3s;
    padding: 12px;
}

.Plan.plus {
    border: 2px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, white, white), var(--gradient-color);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

.Plan.plus button {
    background: var(--gradient-color);
    color: #fff;
}

.Plan.plus button:hover {
    box-shadow: 0px 0px 8px 2px #21c6be65;
}

.Plan.premium {
    border: 2px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, white, white), var(--gradient-gold);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

.Plan.premium button {
    background: var(--gradient-gold);
    color: #333;
}

.Plan.premium button:hover {
    box-shadow: 0px 0px 8px 2px #e2c41a44;
}

.Plan .benefits {
    list-style: none;
    padding: 0;
    margin-top: 24px;
}

.Plan .benefits li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.Plan .benefits li svg {
    font-size: 20px;
    color: #14AE5C;
}

@media (min-width: 992px) {
    .Plan-container {
        flex-direction: row;
    }

    .Plan {
        flex: 1;
    }
}