.AlertInformation {

    --alert-accent-color: #333;

    border-radius: 8px;
    border-left: 4px solid var(--alert-accent-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    &.info {
        --alert-accent-color: #3C84E7;
    }

    &.warning {
        --alert-accent-color: #FF8A37;
    }

    &.danger {
        --alert-accent-color: #E34436;
    }

    &.success {
        --alert-accent-color: #14AE5C;
    }

    & .icon {
        width: 38px;
        height: 38px;
        background-color: var(--alert-accent-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
    }

    & .alert-content {
        flex: 1;
        gap: 8px;

        & .title {
            font-weight: bold;
            font-size: 19px;
            margin: 0;
        }

        & .subtitle {
            font-weight: normal;
            font-size: 16px;
            margin: 0;
        }
    }
}