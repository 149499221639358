.radar {
    position: relative;
    width: 8px;
    height: 8px;
    background: #14AE5C;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(5, 185, 89, 0.5);
    animation: pulseEffect 1.5s infinite ease-in-out;
    display: inline-block;
}

/* Círculos expansivos */
.radar::before,
.radar::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: rgba(5, 185, 89, 0.5);
  transform: translate(-50%, -50%) scale(1);
  animation: pulse 2s infinite;
}

.radar::after {
  animation-delay: 1s; /* Defasagem para o segundo círculo */
}

/* Animação de pulso */
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(6);
    opacity: 0;
  }
}