:root {
    --color-menu-default: #ccc;
    --color-menu-selected: #fff;
    --min-sidebar-width: 320px;
}

.sidebar {
    background-color: #1C252E;
    color: #fff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 1040;
    overflow-y: auto;
}

.sidebar .close {

    display: flex;
    justify-content: end;
    padding: 16px;

    button {
        all: unset;
        border: 1px solid rgba(255, 255, 255, 0.2);
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        color: #fff;
    }
}

.sidebar .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-block: 24px;
    min-height: 290px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-inline: 16px;

    .img-wrapper {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;
    }

    .img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.sidebar .header h6 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-top: 24px;
}

.sidebar .header span {
    font-size: 14px;
    color: #ccc
}

.sidebar .menu {
    padding: 16px;
}

.sidebar .menu ul {
    list-style: none;
    padding: 0;
}

.sidebar .menu li a {
    padding: 12px 16px;
    border-radius: 8px;
    text-decoration: none;
    margin-bottom: 4px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-menu-default);
    font-weight: 300;

    &.selected {
        background-color: rgb(206, 231, 255, 0.1);
        font-weight: 500;
        color: var(--color-menu-selected)
    }

    &:not(.selected):hover {
        background-color: rgb(206, 231, 255, 0.05);
    }
}

@media (min-width: 992px) {

    .sidebar {
        position: relative;
        width: var(--min-sidebar-width);
    }

    .sidebar .close {
        display: none;
    }
}