.btn-delete-rule {
    all: unset;
    border: none;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #666;
    background-color: #00000000;

    &:disabled {
        color: #ccc;
    }
}

.btn-delete-rule:not(:disabled):hover {
    color: #E67D7D;
}
