.btn-account {
    all: unset;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;

    .img-wrapper {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
    }

    .img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}