@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('./components/Plans.css');

:root {
    --gradient-color: linear-gradient(90deg, #333A75, #21C6BF);
    --gradient-gold: linear-gradient(90deg, #DECA57, #FFEF92, #FFE138, #E2C41A);
    --accent-primary: #2799b8;
    --dark: #333;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #F2f2F8;
    font-family: "Nunito", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

#root {
    height: 100vh
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-gradient {
    background: var(--gradient-color);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gold {
    background: var(--gradient-gold);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border-gradient {
    border: 2px solid #2082a0;
}

.border-gold {
    border: 2px solid gold;
}

.btn {
    min-height: 42px;
}

.btn-primary {
    position: relative;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: var(--gradient-color);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    box-shadow: 0px 0px 10px rgba(0, 199, 190, 0.3);
    min-height: 42px;
}

.btn-outline-primary {
    background: var(--gradient-color);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #ddd;
    border-radius: 8px;

    &:hover {
        border: 1px solid #aaa !important;
    }

    &:active {
        border: 1px solid var(--accent-primary) !important;
    }
}

.btn-primary:disabled {
    box-shadow: none;
    animation-play-state: paused;
}

.btn-primary:hover {
    box-shadow: 0px 0px 20px rgba(33, 198, 191, 0.6);
    animation-play-state: paused;
}

.btn-link {
    all: unset;
    color: #0888C7;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    padding: 4px;

    &:hover {
        color: #076b9c;
    }
}

.btn-icon {
    all: unset;
    border-radius: 50%;
    padding: 8px;
}

.btn-icon:hover {
    background-color: #f9f9f9;
}

.btn-icon:active {
    background-color: #f2f2f2;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.card {
    border: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
}

.border-box {
    box-sizing: border-box;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: none;
}

.form-check.form-switch {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;

    & input {
        width: 40px;
        cursor: pointer !important;
        margin: 0;
    }

    & label {
        cursor: pointer !important;
    }
}

.dropdown-menu {
    border: none;
    box-shadow: 0px 0px 8px 4px #00000010;
}

.dropdown-item:focus {
    background-color: var(--accent-primary);
}

.icon-success-feedback {
    color: green;

    & svg {
        font-size: 48px;
    }
}

.icon-pending-feedback {
    color: orange;
    font-size: 48px;

    & svg {
        font-size: 48px;
    }
}

.icon-error-feedback {
    color: #EB5759;
    font-size: 48px;

    & svg {
        font-size: 48px;
    }
}

.elevate-hover {
    transition: transform 0.3s;

    &:hover {
        transform: translateY(-5px);
    }
}

.breadcrumb-item {
    font-size: 16px;

    & a {
        text-decoration: none;
    }
}

table {
    border: none;
    width: 100%;
}

thead {
    background-color: #fff;
    color: #495057;
}

thead th {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    padding-inline: 24px;
}

tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

tbody tr:nth-child(even) {
    background-color: #ffffff;
}

tbody td {
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
    padding-inline: 24px;
}

.overflow-hidden {
    overflow: hidden;
}

.divider {
    height: 1px; 
    background-color: #ddd;
}

.cursor-pointer {
    cursor: pointer;
}

.c-pointer {
    cursor: pointer;
}