.HowItWorks {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-block: 68px;

    .title {
        margin-inline: auto;
        margin-bottom: 48px;
        margin-top: 16px;
    }

    .steps {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        gap: 24px;
    }

    .step {
        text-align: center;
        transition: transform 0.3s;
        flex: 1;
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 16px;

        .stepTitle {
            font-size: 1.2rem;
            color: #333;
            margin-bottom: 8px;
            margin-top: 16px;
        }

        &:hover {
            transform: translateY(-5px);
        }
    }

    @media (min-width: 992px) {
        .steps {
            flex-direction: row;
        }
    }
}