.divider {
    width: 50px;
    color: #bbb;
}

.btn-google {
    background-color: #fff;
    border: none;
    box-shadow: 0px 0px 8px 2px #00000017;
    padding: 12px 16px;
    border-radius: 8px;
    width: 320px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        box-shadow: 0px 0px 8px 4px #00000017;
    }

    &:active {
        background-color: #f6f6f6;
    }
}