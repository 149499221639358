.Home-Telegram {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 48px;

    img {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .Home-Telegram {
        flex-direction: row;
    }
}