.OptionButton {
    all: unset;
    cursor: pointer;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        font-size: 20px;
    }
    
    &.blue {
        background-color: #3C84E7;
        color: #fff;

        &:hover {
            background-color: #276ac7;
        }
        
        &:active {
            background-color: #1d5bb1;
        }
    }

    &.red {
        background-color: #E34436;
        color: #fff;
    
        &:hover {
            background-color: #ad2b20;
        }
        
        &:active {
            background-color: #9b261b;
        }
    }
}