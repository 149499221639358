.StatusDot {
    display: flex;
    align-items: center;
    gap: 8px;

    .ellipse {
        width: 8px;
        height: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        border-radius: 50%;

        &.success {
            background: #14AE5C;
        }

        &.danger {
            background: #f26563;
        }
    }
}