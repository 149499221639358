.welcome-modal {

    .subtitle {
        max-width: 550px;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    @media (min-width: 992px) {
        .modal-lg, .modal-xl {
            --bs-modal-width: 750px;
        }
    }
}