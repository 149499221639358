.pattern-list {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.pattern-view {
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    border: 1px solid #eee;

    .status {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .ellipse {
        width: 8px;
        height: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        border-radius: 50%;

        &.active {
            background: #14AE5C;
        }

        &.inactive {
            background: #EC221F;
        }
    }
}

@media (min-width: 992px) {
    .pattern-list {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .pattern-view {
        padding: 12px 16px;
        flex: 1 1 calc(25% - 8px);
        max-width: calc(25% - 8px);
    }
}