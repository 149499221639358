.PlanVersionButton {
    border: none;
    padding: 12px 8px;
    width: 100%;
    text-transform: uppercase;
    word-spacing: 4px;
    letter-spacing: 1.1px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 8px;
}

.PlanVersionButton.free {
    background-color: #EB5759;
    color: #fff;

    &:hover {
        background-color: #eb4a4d;
    }
}

.PlanVersionButton.plus {
    background: linear-gradient(45deg, #333A75, #21C6BF);
    color: #fff;
}

.PlanVersionButton.premium {
    background: linear-gradient(45deg, #e8d150, #fff8ce, #E2C41A);
    color: #222;
}
