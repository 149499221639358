.Telegram .steps {
    margin-top: 24px;
}

.Telegram .steps p {
    margin: 0;
    margin-bottom: 8px;
}

.Telegram .steps p span {
    font-weight: bold;
    color: #333;
}

.Telegram code {
    padding: 4px 8px;
    background-color: #f2f2f2;
}

.Telegram form {
    margin-top: 32px;
}

.Telegram .form-group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Telegram .form-group input {
    max-width: 350px;
    height: 42px !important;
}