.profile {

    display: flex;
    flex-direction: column;
    align-items: center;

    & .photo {
        border-radius: 50%;
        margin-bottom: 16px;
    }

    & .name {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        margin: 0;
    }

    & .email {
        font-size: 16px;
        color: #555;
    }
}

.nav-account-menu {

    margin-top: 24px;
    width: 100%;
    max-width: 350px;
    margin-inline: auto;

    & ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    & li {
        border: 1px solid #ddd;
        padding: 12px 16px;
        margin-bottom: 8px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &:hover {
            background-color: #fdfdfd;
        }

        &:active {
            background-color: #f9f9f9;
        }

        & .icon {
            font-size: 20px;
        }
    }

    & .plan-type {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: bold;

        & span.free {
            color: red;
        }

        & span.plus {
            background: var(--gradient-color);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        & span.premium {
            color: goldenrod;
        }
    }
}